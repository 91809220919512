import React, { Component  } from 'react'

class BottomBannerAd extends Component {

    componentDidMount() {
     (window.adsbygoogle = window.adsbygoogle || []).push({})
    }

   render () {
    return(
        <div align="center">
            <ins className = "adsbygoogle"
                style={{display:"block"}}
                data-ad-client="ca-pub-6768090493768312"
                data-ad-slot="8729638074"
                data-ad-format="auto"
                data-full-width-responsive="true"></ins>
        </div>)
    }
}

export default BottomBannerAd