import React from 'react';
import {champIcons} from './champIcons';
import { Menu, Alert, Row, Col} from 'antd';
import './spinner.css'
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const _ = require('lodash');

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: "#283b4e",
        color: '#fff',
        boxShadow: theme.shadows[1],
        fontSize: 14,
        
    },
    arrow: {
        color: "#283b4e"
    }
}))(Tooltip);

export default class Spinner extends React.Component {
    constructor(props) {
        super(props);

        this.canvas = undefined;
        this.ctx = undefined;
        this.speed = 20;
        this.scale = 1;
        this.allImages = [];
        this.bouncingImages = [];
        this.bouncingRerollImages = [];

        this.handleClick = this.handleClick.bind(this);
        this.handleRerollClick = this.handleRerollClick.bind(this);

        this.filteredChampIcons = champIcons;

        this.state = {
            dropDownText: "All Champions",
            regionDropDownText: 'North America',
            selectedRegion: 'na1',
            radioSetDisabled: false,
            startDisabled: true,
            displayNameInput: false,
            nameInputValue: '',
            apiError: false,
            hoveredButton: 0,
            selectedPosition: 'All',
            startButtonHover: false,
            mostPlayedDropDownText: 'Top 15 Most Played',
            selectedMostPlayed: '15',
            numberOfLoadedImages: 0,
            mostPlayedRoleSelection: 'All',
            rerollHidden: true,
            rerollButtonHover: false,
            rerollDisabled: false
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        // this.handleClickSummonerName = this.handleClickSummonerName.bind(this);
        this.mouseLeave = this.mouseLeave.bind(this);
        this.mouseLeaveStartButton = this.mouseLeaveStartButton.bind(this);
        this.mouseLeaveRerollButton = this.mouseLeaveRerollButton.bind(this);
    }

    componentDidMount() {
        this.canvas = this.refs.randomizerBox;
        this.ctx = this.canvas.getContext("2d");

        //Draw the "randomizerBox"
        this.canvas.width  = 1000;
        this.canvas.height = 1000;

        this.populatebouncingImages();
        this.populateBouncingYesNos();
    }

    handleClick() {
        this.setState({
            radioSetDisabled: true,
            startDisabled: true,
            rerollDisabled: true  
        });
        this.populatebouncingImages();
        this.update();
    }

    handleRerollClick() {
        this.setState({
            radioSetDisabled: true,
            rerollDisabled: true,
            startDisabled: true   
        });
        this.populateBouncingYesNos();
        this.updateReroll();
    }

    populatebouncingImages() {

        if (this.bouncingImages.length) {
            this.bouncingImages = _.cloneDeep(this.allImages.filter(image => this.filteredChampIcons.some((x => x.name === image.championName))));
            for(const images of this.bouncingImages) {
                images.xspeed = ((Math.random() < 0.5 ? -1 : 1) * (Math.random() * (20 - 0 + 1) + 5));
                images.yspeed = ((Math.random() < 0.5 ? -1 : 1) * (Math.random() * (20 - 0 + 1) + 5));
            }

        } else {
            for (let i = 0; i < this.filteredChampIcons.length; i++) {
                this.bouncingImages[i] = {
                    x: 500,
                    y: 500,
                    xspeed: ((Math.random() < 0.5 ? -1 : 1) * (Math.random() * (20 - 0 + 1) + 5)),
                    yspeed: ((Math.random() < 0.5 ? -1 : 1) * (Math.random() * (20 - 0 + 1) + 5)),
                    img: new Image(),
                    fullImgUrl: '',
                    bounces: 0,
                    lastBounce: undefined,
                    championName: ''
                }
            }
    
            for (let j = 0; j < this.filteredChampIcons.length; j++) { 
                this.bouncingImages[j].img.onLoad = this.incrementNumberOfLoaded(this.bouncingImages[j].img);
                this.bouncingImages[j].img.src = this.filteredChampIcons[j].base64String;

                // this was the way I was reffering to the files directly
                // this.bouncingImages[j].img.src = '/champIcons/' + this.filteredChampIcons[j].name + '_0.webp';
                this.bouncingImages[j].fullImgUrl = '/champIconsBig/' + this.filteredChampIcons[j].name + '.webp';
                // this.bouncingImages[j].fullImgUrl = this.filteredChampIcons[j].imgUrl;
                this.bouncingImages[j].championName = this.filteredChampIcons[j].name;
            }
            this.allImages = this.bouncingImages;
        }


        // // Uncomment this and change name when testing if new champ worked
        // const indexIWant = this.bouncingImages.findIndex((x) => x.championName == 'Aurora' );
        // this.bouncingImages[indexIWant].xspeed = .001
        // this.bouncingImages[indexIWant].yspeed = .001

    }

    populateBouncingYesNos() {

        if ( this.bouncingRerollImages.length ) {
            for(const images of this.bouncingRerollImages) {
                images.x = 500;
                images.y = 500;
                images.lastBounce = undefined;
                images.bounces = 0;
                images.xspeed = ((Math.random() < 0.5 ? -1 : 1) * (Math.random() * (20 - 0 + 1) + 5));
                images.yspeed = ((Math.random() < 0.5 ? -1 : 1) * (Math.random() * (20 - 0 + 1) + 5));
            }
        } else {
            for (let i = 0; i < 200; i++) {
                this.bouncingRerollImages[i] = {
                    x: 500,
                    y: 500,
                    xspeed: ((Math.random() < 0.5 ? -1 : 1) * (Math.random() * (20 - 0 + 1) + 5)),
                    yspeed: ((Math.random() < 0.5 ? -1 : 1) * (Math.random() * (20 - 0 + 1) + 5)),
                    img: new Image(),
                    fullImgUrl: '',
                    bounces: 0,
                    lastBounce: undefined
                }
            }
    
            for (let j = 0; j < 200; j++) {
                if (j % 2 === 0) {
                    this.bouncingRerollImages[j].img.src = 'Yes.webp' 
                    this.bouncingRerollImages[j].fullImgUrl = 'bigYes.webp';
                } else {
                    this.bouncingRerollImages[j].img.src = 'No.webp'
                    this.bouncingRerollImages[j].fullImgUrl = 'bigNo.webp';
                }
            }
        }

    }

    incrementNumberOfLoaded(image) {

        if(this.state.numberOfLoadedImages >= champIcons.length) {

        } else if (image.complete) 
        
        {
            this.setState({
                startDisabled: true,
            });

            this.setState((state) => {
                return {
                    numberOfLoadedImages:  state.numberOfLoadedImages + 1
                }
            }, () => {
                        if(this.state.numberOfLoadedImages >= 148) {
                            this.setState({
                                startDisabled: false,
                            });
                        }
                    }
            )
        }

    }

    incrementBounces(icon) {
        icon.bounces = icon.bounces + 1;
        icon.lastBounce = Date.now();
    }

    clearCanvas() {
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    }

    updateReroll() {
        setTimeout(() => {
            //Draw the canvas background
            this.ctx.fillStyle = '#000';
            
            this.clearCanvas();
            //Draw bouncingImages Logo and his background

            var options = 0;
            var potentialWinner;

            for (let i = 0; i < 200; i++) { 

                if (this.bouncingRerollImages[i].bounces < 3) {

                    this.ctx.drawImage(this.bouncingRerollImages[i].img, this.bouncingRerollImages[i].x, this.bouncingRerollImages[i].y, this.bouncingRerollImages[i].img.width*this.scale, this.bouncingRerollImages[i].img.height*this.scale);
                
                    options++; 

                    if (options === 1) {
                        potentialWinner = i;
                    }
            
            //Move the logo
                    this.bouncingRerollImages[i].x+=this.bouncingRerollImages[i].xspeed;
                    this.bouncingRerollImages[i].y+=this.bouncingRerollImages[i].yspeed; 
                    this.checkRerollHitBox(i);
                }
            }

            if (options === 1) {
                this.drawRerollWinner(potentialWinner);
            } else if (options === 0 ) {
                var winner = 0;
                var winnerIndex;
                for (let k = 0; k < 200; k++) { 
                    if (this.bouncingRerollImages[k].lastBounce > winner ) {
                        winner = this.bouncingRerollImages[k].lastBounce;
                        winnerIndex = k;
                    }
                }
                this.drawRerollWinner(winnerIndex);
            } else {
                //Check for collision 
                this.updateReroll();  
            }
        }, this.speed)
    }

    update() {
        setTimeout(() => {
            //Draw the canvas background
            this.ctx.fillStyle = '#000';
            
            this.clearCanvas();
            //Draw bouncingImages Logo and his background

            var options = 0;
            var potentialWinner;

            for (let i = 0; i < this.filteredChampIcons.length; i++) { 

                if (this.bouncingImages[i].bounces < 3) {

                    this.ctx.drawImage(this.bouncingImages[i].img, this.bouncingImages[i].x, this.bouncingImages[i].y, this.bouncingImages[i].img.width*this.scale, this.bouncingImages[i].img.height*this.scale);
                
                    options++; 

                    if (options === 1) {
                        potentialWinner = i;
                    }
            
            //Move the logo
                    this.bouncingImages[i].x+=this.bouncingImages[i].xspeed;
                    this.bouncingImages[i].y+=this.bouncingImages[i].yspeed; 
                    this.checkHitBox(i);
                }
            }

            if (options === 1) {
                this.drawWinner(potentialWinner);
            } else if (options === 0 ) {
                var winner = 0;
                var winnerIndex;
                for (let k = 0; k < this.filteredChampIcons.length; k++) { 
                    if (this.bouncingImages[k].lastBounce > winner ) {
                        winner = this.bouncingImages[k].lastBounce;
                        winnerIndex = k;
                    }
                }
                this.drawWinner(winnerIndex);
            } else {
                //Check for collision 
                this.update();  
            }
        }, this.speed)
    }

    drawWinner(index) {
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        let winner = new Image();
        winner.onload = () => { 
            this.ctx.drawImage(winner, 310,235);
        };
        winner.src = this.bouncingImages[index].fullImgUrl;
        this.ctx.font = "100px  Inter";
        this.ctx.fillStyle = "#ececec";
        this.ctx.textAlign = "center";

        if (this.bouncingImages[index].championName === "MonkeyKing") {
            this.ctx.fillText("Wukong",  500, 750);
        } else {
            this.ctx.fillText(this.bouncingImages[index].championName,  500, 750);
        }

        
        if (!this.state.displayNameInput) {
            document.getElementById("rngButton").disabled = false;
        }

        this.setState({
            startDisabled: false,
            radioSetDisabled: false,
            rerollHidden: false,
            rerollDisabled: false
        });
    }

    drawRerollWinner(index) {
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        let winner = new Image();
        winner.onload = () => { 
            this.ctx.drawImage(winner, 310,235);
        };
        winner.src = this.bouncingRerollImages[index].fullImgUrl;

        this.setState({
            startDisabled: false,
            radioSetDisabled: false,
            rerollHidden: true,
            rerollDisabled: true
        });
    }
    
    //Check for border collision
    checkHitBox(i){
        if(this.bouncingImages[i].x+this.bouncingImages[i].img.width*this.scale >= this.canvas.width || this.bouncingImages[i].x <= 0){
            this.bouncingImages[i].xspeed *= -1;

            this.incrementBounces(this.bouncingImages[i]);
        }
            
        if(this.bouncingImages[i].y+this.bouncingImages[i].img.height*this.scale >= this.canvas.height || this.bouncingImages[i].y <= 0){
            this.bouncingImages[i].yspeed *= -1;

            this.incrementBounces(this.bouncingImages[i]);
        }    
    }

    //Check for border collision
    checkRerollHitBox(i){
        if(this.bouncingRerollImages[i].x+this.bouncingRerollImages[i].img.width*this.scale >= this.canvas.width || this.bouncingRerollImages[i].x <= 0){
            this.bouncingRerollImages[i].xspeed *= -1;

            this.incrementBounces(this.bouncingRerollImages[i]);
        }
            
        if(this.bouncingRerollImages[i].y+this.bouncingRerollImages[i].img.height*this.scale >= this.canvas.height || this.bouncingRerollImages[i].y <= 0){
            this.bouncingRerollImages[i].yspeed *= -1;

            this.incrementBounces(this.bouncingRerollImages[i]);
        }    
    }

    // onFilterDropDownSelection = ({key}) => {
    //     this.setState({
    //         dropDownText: key + " Champions"
    //     });
        
    //     this.clearCanvas();

    //     if (key === "All"){
    //         this.filteredChampIcons = champIcons;
    //         this.setDisplayNameStateFalse();
    //     } else if (key === "My Most Played") {
    //         this.setState({
    //             displayNameInput: true
    //         });
    //     } else {
    //         this.filteredChampIcons = champIcons.filter(function (champ) {
    //             return champ.roles.includes(key);
    //         });
    //         this.setDisplayNameStateFalse();
    //     }
    //     this.populatebouncingImages();
    // };

    onRegionDropDownSelection = ({key}) => {
        var values = key.split(',');
        this.setState({
            regionDropDownText: values[1],
            selectedRegion: values[0]
        })
    }

    onMostPlayedDropDownSelection = ({key}) => {
        var values = key.split(',');
        this.setState({
            mostPlayedDropDownText: values[1],
            selectedMostPlayed: values[0]
        })
    }

    onRoleSelectMenuDropDownSelection = ({key}) => {
        this.setState({
            mostPlayedRoleSelection: key
        })
    }

    setDisplayNameStateFalse() {
        if (this.state.displayNameInput) {
            this.setState({
                displayNameInput: false
            });
            this.setApiErrorStateFalse();
        }
    }

    setApiErrorStateFalse() {
        this.setState({
            apiError: false
        });
    }

    setApiErrorStateTrue() {
        this.setState({
            apiError: true
        });

        this.setState({
            startDisabled: false,
            radioSetDisabled: false 
        });
    }
      
    regionMenu = ( 
        <Menu onClick={this.onRegionDropDownSelection}>
            <Menu.Item key="na1,North America">North America</Menu.Item>
            <Menu.Item key="euw1,Europe West">Europe West</Menu.Item>
            <Menu.Item key="eun1,Europe North & East">Europe North & East</Menu.Item>
            <Menu.Item key="kr,Korea">Korea</Menu.Item>
            <Menu.Item key="br1,Brazil">Brazil</Menu.Item>
            <Menu.Item key="jp1,Japan">Japan</Menu.Item>
            <Menu.Item key="ru,Russia">Russia</Menu.Item>
            <Menu.Item key="oc1,Oceania">Oceania</Menu.Item>
            <Menu.Item key="tr1,Turkey">Turkey</Menu.Item>
            <Menu.Item key="la1,Latin America North">Latin America North</Menu.Item>
            <Menu.Item key="la2,Latin America South">Latin America South</Menu.Item>
        </Menu> 
    )

    mostPlayedChampsMenu = ( 
        <Menu onClick={this.onMostPlayedDropDownSelection}>
            <Menu.Item key="5,Top 5 Most Played">Top 5 Most Played</Menu.Item>
            <Menu.Item key="10,Top 10 Most Played">Top 10 Most Played</Menu.Item>
            <Menu.Item key="15,Top 15 Most Played">Top 15 Most Played</Menu.Item>
            <Menu.Item key="20,Top 20 Most Played">Top 20 Most Played</Menu.Item>
            <Menu.Item key="25,Top 25 Most Played">Top 25 Most Played</Menu.Item>
            <Menu.Item key="50,Top 50 Most Played">Top 50 Most Played</Menu.Item>
            <Menu.Item key="100,Top 100 Most Played">Top 100 Most Played</Menu.Item>
        </Menu> 
    )

    roleSelectMenu = (
        <Menu onClick={this.onRoleSelectMenuDropDownSelection}>
            <Menu.Item key="All">All</Menu.Item>
            <Menu.Item key="Top">Top</Menu.Item>
            <Menu.Item key="Jungle">Jungle</Menu.Item>
            <Menu.Item key="Mid">Mid</Menu.Item>
            <Menu.Item key="ADC">ADC</Menu.Item>
            <Menu.Item key="Support">Support</Menu.Item>
        </Menu> 
    )

    // handleClickSummonerName() {

    //     if(this.state.nameInputValue !== '') {
    //         this.setState({
    //             radioSetDisabled: true,
    //             startDisabled: true  
    //         });

    //         api.getChampionMasteriesFromSummonerName(this.state.nameInputValue, this.state.selectedRegion).then((dat) => {
    //             if (dat === "Error" || dat.length === 0 || typeof dat[0] === 'undefined'  ) {
    //                 this.setApiErrorStateTrue();
    //             } else {
    //                 this.filteredChampIcons = [];

    //                 let index = 0;
    //                 while ( this.filteredChampIcons.length < Math.min(this.state.selectedMostPlayed, dat.length)) {
    //                     let foundChamp = champIcons.find(x => x.championId === (dat[index].championId));
    //                     if (this.state.mostPlayedRoleSelection === "All") {
    //                         this.filteredChampIcons.push(foundChamp);
    //                     } else {
    //                         if (foundChamp.roles.includes(this.state.mostPlayedRoleSelection)) {
    //                             this.filteredChampIcons.push(foundChamp); 
    //                         }
    //                     }
    //                     index++;

    //                     if (index === dat.length) break;
    //                 }
    //                 this.handleClick();
    //             }
    //         });
    //     } else {

    //     }
    // }

    handleInputChange(event) {
        this.setState({nameInputValue: event.target.value});
    }

    handleClose = () => {
        this.setApiErrorStateFalse();
    };

    mouseEnter(a) {
        this.setState({
            buttonNumber: a
        });
    }

    mouseLeave() {
        this.setState({
            buttonNumber: 0
        });
    }

    mouseEnterStartButton() {
        this.setState({
            startButtonHover: true
        });
    }

    mouseLeaveStartButton() {
        this.setState({
            startButtonHover: false
        });
    }

    mouseEnterRerollButton() {
        this.setState({
            rerollButtonHover: true
        });
    }

    mouseLeaveRerollButton() {
        this.setState({
            rerollButtonHover: false
        });
    }

    onPositionSelection(position) {
        this.setState({
            selectedPosition: position
        });

        this.clearCanvas();

        if (position === "All"){
            this.filteredChampIcons = champIcons;
            this.setDisplayNameStateFalse();
        } else if (position === "Most Played") {
            this.setState({
                displayNameInput: true
            });
        } else {
            this.filteredChampIcons = champIcons.filter(function (champ) {
                return champ.roles.includes(position);
            });
            this.setDisplayNameStateFalse();
        }
        this.populatebouncingImages();
    }

    render() {
        return (
            <div>

                <Row justify="center" align="middle" gutter={[16, 24]}>
                    <Col>
                        <ButtonGroup disabled={this.state.radioSetDisabled} size="small">
                            <LightTooltip title="All" placement="top" arrow>
                                <Button  onClick={ () => this.onPositionSelection("All")} onMouseEnter={ () => this.mouseEnter(1)} onMouseLeave={this.mouseLeave} style={{ backgroundImage: "url(/positionIcons/positionicon-all-grey.png)",      backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "50% 50%", height: "3.2em", width: "3.2em", backgroundColor: this.state.selectedPosition === "All" ? "#407ebd" : this.state.buttonNumber === 1 ? "#446280" : "#283b4e"  }}> </Button>
                            </LightTooltip>
                            <LightTooltip title="Top" placement="top" arrow>
                                <Button  onClick={ () => this.onPositionSelection("Top")} onMouseEnter={ () => this.mouseEnter(2)} onMouseLeave={this.mouseLeave} style={{ backgroundImage: "url(/positionIcons/positionicon-top-grey.png)",      backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "50% 50%", height: "3.2em", width: "3.2em", backgroundColor: this.state.selectedPosition === "Top" ? "#407ebd" : this.state.buttonNumber === 2 ? "#446280" : "#283b4e"  }}> </Button>
                            </LightTooltip>
                            <LightTooltip title="Jungle" placement="top" arrow>
                                <Button onClick={ () => this.onPositionSelection("Jungle")} onMouseEnter={ () => this.mouseEnter(3)} onMouseLeave={this.mouseLeave} style={{ backgroundImage: "url(/positionIcons/positionicon-jungle-grey.png)",   backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "50% 50%", height: "3.2em", width: "3.2em", backgroundColor: this.state.selectedPosition === "Jungle" ? "#407ebd" : this.state.buttonNumber === 3 ? "#446280" : "#283b4e" }}> </Button>
                            </LightTooltip>
                            <LightTooltip title="Mid" placement="top" arrow>
                                <Button onClick={ () => this.onPositionSelection("Mid")} onMouseEnter={ () => this.mouseEnter(4)} onMouseLeave={this.mouseLeave} style={{ backgroundImage: "url(/positionIcons/positionicon-mid-grey.png)",      backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "50% 50%", height: "3.2em", width: "3.2em", backgroundColor: this.state.selectedPosition === "Mid" ? "#407ebd" : this.state.buttonNumber === 4 ? "#446280" : "#283b4e" }}> </Button>
                            </LightTooltip>
                            <LightTooltip title="Bot" placement="top" arrow>
                                <Button onClick={ () => this.onPositionSelection("ADC")} onMouseEnter={ () => this.mouseEnter(5)} onMouseLeave={this.mouseLeave} style={{ backgroundImage: "url(/positionIcons/positionicon-bottom-grey.png)",   backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "50% 50%", height: "3.2em", width: "3.2em", backgroundColor: this.state.selectedPosition === "ADC" ? "#407ebd" : this.state.buttonNumber === 5 ? "#446280" : "#283b4e" }}> </Button>
                            </LightTooltip>
                            <LightTooltip title="Support" placement="top" arrow>
                                <Button onClick={ () => this.onPositionSelection("Support")} onMouseEnter={ () => this.mouseEnter(6)} onMouseLeave={this.mouseLeave} style={{ backgroundImage: "url(/positionIcons/positionicon-support-grey.png)",  backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "50% 50%", height: "3.2em", width: "3.2em", backgroundColor: this.state.selectedPosition === "Support" ? "#407ebd" : this.state.buttonNumber === 6 ? "#446280" : "#283b4e" }}> </Button>
                            </LightTooltip>
                            {/* <LightTooltip title="My Most Played" placement="top" arrow>
                                <Button onClick={ () => this.onPositionSelection("Most Played")} onMouseEnter={ () => this.mouseEnter(7)} onMouseLeave={this.mouseLeave} style={{ backgroundImage: "url(/positionIcons/positionicon-person.png)",  backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "50% 50%", height: "3.2em", width: "3.2em", backgroundColor: this.state.selectedPosition === "Most Played" ? "#407ebd" : this.state.buttonNumber === 7 ? "#446280" : "#283b4e" }}> </Button>
                            </LightTooltip> */}
                        </ButtonGroup>
                    </Col>
                </Row>

                <Row justify="center" align="middle" gutter={[16, 24]}>
                    {this.state.apiError && 
                        <Alert message="Something went wrong! Please double check your summoner name/region and try again." type="error" showIcon closable afterClose={this.handleClose} />
                    }
                </Row>
                
                {/* <Row justify="center" align="middle" gutter={[16, 24]}>
                    <Col>
                        {this.state.displayNameInput &&
                            <Input disabled={this.state.radioSetDisabled} type="text" placeholder="SummonerName" value={this.state.nameInputValue} onChange={this.handleInputChange}></Input>
                        }
                    </Col>

                    <Col>
                        {this.state.displayNameInput &&
                            <Dropdown placement="top" disabled={this.state.radioSetDisabled} overlay={this.regionMenu}>
                                <a href="/#" className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                    {this.state.regionDropDownText} <DownOutlined />
                                </a>
                            </Dropdown>
                        }
                    </Col>
                    <Col>
                        {this.state.displayNameInput &&
                            <Dropdown placement="bottom" disabled={this.state.radioSetDisabled} overlay={this.mostPlayedChampsMenu}>
                                <a href="/#" className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                    {this.state.mostPlayedDropDownText} <DownOutlined />
                                </a>
                            </Dropdown>
                        }
                    </Col>
                    <Col>
                        {this.state.displayNameInput &&
                            <Dropdown placement="bottom" disabled={this.state.radioSetDisabled} overlay={this.roleSelectMenu}>
                                <a href="/#" className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                    {this.state.mostPlayedRoleSelection} <DownOutlined />
                                </a>
                            </Dropdown>
                        }
                    </Col>
                </Row> */}

                <div className="wrapper">
                    <canvas className="secondary-color border" id="responsive-canvas" ref="randomizerBox"></canvas>

                    {this.state.numberOfLoadedImages < champIcons.length &&
                        <CircularProgress className="spinner" size="20rem"></CircularProgress>
                    }
                </div>

                <div >
                    <Row justify="center" align="middle" gutter={[16, 24]}>
                        <Col>
                            {this.state.displayNameInput
                                ? <Button disabled={this.state.startDisabled} id="sumNameSpinButton" color="primary" disableElevation variant="contained" onClick={this.handleClickSummonerName} style={{backgroundColor: this.state.startButtonHover ? "#446280" : '#283b4e', borderRadius: '4', minWidth: '124px', minHeight: '62px', fontFamily: 'Inter', fontSize: '2em', margin: '6px 3px 6px 3px'}} onMouseEnter={ () => this.mouseEnterStartButton()} onMouseLeave={this.mouseLeaveStartButton}>Start</Button>
                                : <Button disabled={this.state.startDisabled} id="rngButton" color="primary" disableElevation variant="contained" onClick={this.handleClick} style={{backgroundColor: this.state.startButtonHover ? "#446280" : '#283b4e', borderRadius: '4', minWidth: '124px', minHeight: '62px', fontFamily: 'Inter', fontSize: '2em', margin: '6px 3px 6px 3px'}} onMouseEnter={ () => this.mouseEnterStartButton()} onMouseLeave={this.mouseLeaveStartButton}>Start</Button>
                            }
                        </Col>
                        <Col>
                            <Button disabled={this.state.rerollDisabled} hidden={this.state.rerollHidden} id="rerollButton" color="primary" disableElevation variant="contained" onClick={this.handleRerollClick} style={{backgroundColor: this.state.rerollButtonHover ? "#446280" : '#283b4e', borderRadius: '4', minWidth: '124px', minHeight: '62px', fontFamily: 'Inter', fontSize: '2em', margin: '6px 3px 6px 3px'}} onMouseEnter={ () => this.mouseEnterRerollButton()} onMouseLeave={this.mouseLeaveRerollButton}>Reroll?</Button>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
} 


