import React from 'react';
import './title.css';

export default class Title extends React.Component {
    static propTypes = {}; 


    render() {
        return (
            <div>
                <img alt="Elo.Rip" className="icon-top" src="/EloRipTitle5.png" ></img>
                <h1 className="subtitle">A League of Legends Champion Randomizer</h1>
            </div>
        )
    }

} 